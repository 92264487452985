<template>
	<v-container fluid>
		<v-card>
			<v-card-text>
				<crud
					:model="'Bank'"
					create-permission="Agregar Bancos"
					edit-permission="Editar Bancos"
					delete-permission="Eliminar Bancos"
					resource="banks"
					:filters="filters"
					:headers="headers"
					:default-order="defaultOrder"
					:load="load"
					show-clone
					bulk-actions
				>
				</crud>
			</v-card-text>
		</v-card>
	</v-container>
</template>
<script>
import model from "@/models/banks";
const { headers, filters, load, defaultOrder } = model;
export default {
	beforeMount() {},
	data() {
		return {
			item: null,
			headers,
			filters,
			load,
			defaultOrder,
		};
	},
};
</script>
